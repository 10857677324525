exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-clients-tsx": () => import("./../../../src/pages/clients.tsx" /* webpackChunkName: "component---src-pages-clients-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-data-scraper-tsx": () => import("./../../../src/pages/data-scraper.tsx" /* webpackChunkName: "component---src-pages-data-scraper-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */)
}

